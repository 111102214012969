/* eslint-disable no-prototype-builtins */
import SharedLibs from 'wizarding-world-web-shared';
import union from 'lodash/union';
import Bugsnag from '@bugsnag/js';
import hasTag from '@utils/hasTag';
import logger from '@utils/logger';
import { findTagName } from './hubs';
import { numOfItemPerPage, HIDE_FROM_WEB_TAG } from '../constants';
import { updateContentDates } from './date';
import { FETCH_CONTENT_QUERY } from './graphQueries';

const generalExcludeTags = [HIDE_FROM_WEB_TAG];
const PRUNE_HIDE_FROM_WEB_DEPTH = 3;
const CONTENTFUL_IMAGE_CDN = process.env.REACT_APP_CONTENTFUL_IMAGE_CDN_DOMAIN;
const WBD_IMAGE_CDN = process.env.REACT_APP_WBD_IMAGE_CDN_DOMAIN;

export const mergeVariables = (variables) => {
  return {
    ...variables,
    excludeTags: variables.excludeTags
      ? union(variables.excludeTags, generalExcludeTags)
      : generalExcludeTags,
  };
};

export const parseData = (data) => {
  const items = data?.content?.results ?? [];
  return items.map((item) => {
    const wasParsed = typeof item.body !== 'string';

    if (wasParsed) {
      return item;
    }

    let processedBody = item.body;

    // Fix missing protocol on image URLs
    processedBody = processedBody.replaceAll(
      `"//${CONTENTFUL_IMAGE_CDN}`,
      `"https://${CONTENTFUL_IMAGE_CDN}`,
    );

    if (process.env.REACT_APP_WBD_IMAGE_CDN_ENABLED === 'TRUE') {
      processedBody = processedBody.replaceAll(CONTENTFUL_IMAGE_CDN, WBD_IMAGE_CDN);
    }

    return {
      ...item,
      body: JSON.parse(processedBody),
    };
  });
};

// Will remove the relatedArticleReference array
export const pruneRelated = (contentArray = []) => {
  return contentArray.map((article) => {
    if (!article?.relatedArticleReference) {
      return article;
    }
    return {
      ...article,
      relatedArticleReference: [],
    };
  });
};

export const pruneCollection = (collection) => {
  return {
    ...collection,
    spotlight: pruneRelated([collection.spotlight])[0],
    referencedContent: pruneRelated(collection.referencedContent),
    referencedContentStatic: pruneRelated(collection.referencedContentStatic),
    secondaryReferencedContentCarousel: pruneRelated(collection.secondaryReferencedContentCarousel),
  };
};

export const getHubData = (content, hubName) => {
  const filteredData =
    content && Array.isArray(content)
      ? content.filter((x) => (x.body?.externalId ?? '') === hubName)
      : [];
  const hubData = filteredData[0]?.body ?? null;
  return hubData;
};

export const pruneHideFromWeb = (responseData, currentLevel = 1) => {
  const data = responseData;
  if (!data) return data;

  try {
    // if top level data is an array, then check each child
    if (Array.isArray(data)) {
      // eslint-disable-next-line no-unused-expressions, no-unused-vars
      data?.forEach((dataItem) => {
        // eslint-disable-next-line no-param-reassign, no-unused-vars
        dataItem = pruneHideFromWeb(dataItem, currentLevel);
      });
      return data;
    }

    const fields = Object.keys(data);

    // check each field of an object
    fields.forEach((field) => {
      let subItem = data[field];

      // if it's an array, then filter out all that have the hide tag
      if (Array.isArray(subItem)) {
        subItem = subItem.filter((item) => !hasTag(item, HIDE_FROM_WEB_TAG));
        if (currentLevel < PRUNE_HIDE_FROM_WEB_DEPTH) {
          // eslint-disable-next-line no-unused-vars
          subItem.forEach((remainingItem) => {
            // eslint-disable-next-line no-param-reassign, no-unused-vars
            remainingItem = pruneHideFromWeb(remainingItem, currentLevel + 1);
          });
        }
        // if it's an object, and has the tag, kill it
      } else if (hasTag(subItem, HIDE_FROM_WEB_TAG)) {
        subItem = null;
        // if it's an object, doesn't have the tag, and we haven't hit the depth limit
        // then keep digging down.
      } else if (currentLevel < PRUNE_HIDE_FROM_WEB_DEPTH) {
        if (typeof subItem === 'object' && subItem !== null) {
          subItem = pruneHideFromWeb(subItem, currentLevel + 1);
        }
      }
      data[field] = subItem;
    });
  } catch (e) {
    /* istanbul ignore next */
    Bugsnag.notify(e);
  }

  return data;
};

// This method will always return in the format [data, errors]
// This prevents the need to wrap it in a try catch using await.
export const fetchContent = async (variables) => {
  const { api: wizardingWorldApi } = SharedLibs.services;
  return wizardingWorldApi()
    .query({
      query: FETCH_CONTENT_QUERY,
      variables: mergeVariables(variables),
    })
    .then((response) => {
      const prunedResponse = pruneHideFromWeb(parseData(response.data));
      return [prunedResponse, null];
    })
    .catch((error) => {
      const msg = error.message;
      let statusCode = 404;
      if (msg.includes('403') || msg.includes('500')) {
        statusCode = 500;
      }

      const errorObj = {
        error,
        statusCode,
      };

      // Report to Bugsnag.
      if (process.browser) {
        window.onerror(error);
      }

      return [null, errorObj];
    });
};

export const awaitFetchContent = async (contentType, externalId) => {
  const [data] = await fetchContent({
    contentTypes: [contentType],
    externalId,
  });

  // istanbul ignore next
  return (data && data[0]?.body) ?? null;
};

export const getMoreToExploreFromHubName = async (content, query, hubName, sortBy = []) => {
  const hubData = getHubData(content, hubName);
  const tags = hubData?.associatedTag ?? [];
  const tag = findTagName(tags);
  const articles = hubData?.articles ?? [];
  const referencedContent = hubData?.referencedContent ?? articles;
  const excludeExternalIds = referencedContent.map((c) => c.contentfulId);

  const [moreToExplore, moreToExploreErrors] = await fetchContent({
    tags: tag,
    count: numOfItemPerPage,
    offset: query.page ? (parseInt(query.page, 10) - 1) * numOfItemPerPage : 0,
    sortBy,
    excludeIds: excludeExternalIds,
  });
  const location = `Page: /${hubName}`;
  logger(location, moreToExplore, moreToExploreErrors);
  return [moreToExplore, moreToExploreErrors];
};

export const fetchNavigation = async (externalId) => {
  const [data] = await fetchContent({
    contentTypes: ['navigation'],
    externalId,
  });

  return (data && data[0]?.body) ?? null;
};

export const withNavigation = async (props = {}) => {
  const [navData, footerData] = await Promise.all([
    fetchNavigation('site-navigation'),
    fetchNavigation('footer'),
  ]);

  return {
    navData,
    footerData,
    ...props,
  };
};

export const fetchFeatureFlags = async () => {
  const [flags, contentErrors] = await fetchContent({
    contentTypes: ['featureFlag'],
  });

  if (!flags || !flags.length || contentErrors) {
    return {
      flags: null,
      errors: contentErrors,
    };
  }

  return {
    flags,
    errors: contentErrors,
  };
};

export const getAllHubData = async ({
  hubName,
  moreToExplorePageNum = 1,
  moreToExploreSortBy = [],
}) => {
  const [hubContent, contentErrors] = await fetchContent({
    contentTypes: ['hub'],
    externalId: hubName,
  });
  const location = `Page: /${hubName}`;
  logger(location, hubContent, contentErrors);
  if (!hubContent || !hubContent.length || contentErrors) {
    return {
      content: null,
      moreToExplore: null,
      errors: contentErrors,
    };
  }

  const [moreToExploreData, moreToExploreErrors] = await getMoreToExploreFromHubName(
    hubContent,
    { page: moreToExplorePageNum },
    hubName,
    moreToExploreSortBy,
  );

  const moreToExplore = moreToExploreData?.map((article) => {
    return {
      ...article,
      body: {
        ...article.body,
        relatedArticleReference: [],
      },
    };
  });

  const content = {
    ...hubContent[0].body,
    referencedContent: pruneRelated(hubContent[0].body.referencedContent),
  };

  return {
    content,
    moreToExplore,
    errors: moreToExploreErrors,
  };
};

export const normalizeJKROriginals = (content) => {
  return content.map((data) => {
    if (data.contentTypeId === 'writingByJKRowling') {
      const section = data.body.section
        .map((sectionItem, sectionIndex) => {
          if (sectionItem.contentTypeId !== 'textSection') {
            return sectionItem;
          }

          const splitText = sectionItem.text.split(/\n\n/);
          return splitText.map((text, index) => {
            return {
              ...sectionItem,
              contentfulId: `${sectionItem.contentfulId}-${sectionIndex}-${index}`,
              text,
            };
          });
        })
        .flat();

      const intro =
        !data.body.intro && /^[a-zA-Z0-9]/.test(section[0].text)
          ? section.shift().text
          : data.body.intro;

      return {
        ...data,
        body: {
          ...data.body,
          intro,
          section,
        },
      };
    }
    return data;
  });
};

export const convertArticles = (content) => {
  let converted = content;
  converted = normalizeJKROriginals(content);
  converted = updateContentDates(converted, 'activationDate', 'MMM do yyyy');
  return converted;
};

export const fetchAllPagedContent = async (
  variables = { contentTypes: ['page'] },
  pages = [], // do not modify
  offset = 0, // do not modify
) => {
  const { api: wizardingWorldApi } = SharedLibs.services;

  const paginationSize = 20;
  const query = async () => {
    let response;
    try {
      response = await wizardingWorldApi().query({
        query: FETCH_CONTENT_QUERY,
        variables: mergeVariables({
          count: paginationSize,
          offset,
          ...variables,
        }),
      });
    } catch (error) {
      const msg = error?.message;
      let statusCode = 404;
      if (msg?.includes('403') || msg?.includes('500')) {
        statusCode = 500;
      }

      // Report to Bugsnag.
      // istanbul ignore else
      if (process.browser) {
        window.onerror(error);
      }

      // If any pagination calls fail, abort the operation.
      // This essentially voids any integrity of the list,
      // so short circuit everything.
      return [
        null,
        {
          error,
          statusCode,
        },
      ];
    }

    return [parseData(response.data), null];
  };

  const [queryResults, error] = await query();
  if (error) {
    return [null, error];
  }

  if (!queryResults) {
    return [[], null];
  }

  let allPages = pages;
  // istanbul ignore else
  if (queryResults && queryResults?.length) {
    allPages = pages.concat(queryResults);
  }

  if (queryResults.length === paginationSize) {
    const [nextPage, nextError] = await fetchAllPagedContent(
      variables,
      allPages,
      offset + paginationSize,
    );
    return [nextPage, nextError];
  }

  return [allPages, null];
};

let validPageUrls = [];
let lastUpdated = 0;
export const getValidPageUrls = () => validPageUrls;

// These are the only pages that should be excluded from the generic page url validation
// we should expect to remove this list and the filter in updateValidPageUrls in the future
// as the house-results page goes away -pb
export const validPageUrlsBlackList = [
  'ja/house-results/gryffindor',
  'ja/house-results/slytherin',
  'ja/house-results/hufflepuff',
  'ja/house-results/ravenclaw',
  'house-results/gryffindor',
  'house-results/slytherin',
  'house-results/hufflepuff',
  'house-results/ravenclaw',
];

export const updateValidPageUrls = async () => {
  const revalidateShort = Number(process.env.REVALIDATION_TIMEOUT_SHORT) || 60 * 5;
  const currentDate = Date.now();
  if (currentDate - lastUpdated <= revalidateShort * 1000) {
    return false;
  }

  validPageUrls = [];
  lastUpdated = currentDate;

  const [pages, error] = await fetchAllPagedContent({ contentTypes: ['page'] });

  if (!pages || error) {
    lastUpdated = 0;
    validPageUrls = [];
    return false;
  }

  validPageUrls = pages
    .filter((page) => !validPageUrlsBlackList.includes(page.body.externalId))
    .map((page) => page.body.externalId);

  return true;
};

export const isValidGenericPageURL = (externalId) => {
  // Update valid page url list in the background.
  updateValidPageUrls();

  const validRegex = /^(?!\/)[0-9a-z-/]+[0-9a-z]$/;
  if (
    (validPageUrls.length && !validPageUrls.includes(externalId)) ||
    !externalId.match(validRegex) ||
    externalId.length > 256
  ) {
    return false;
  }

  return true;
};
