import { useEffect } from 'react';
import { useRouter } from 'next/router';
import SharedLibs from 'wizarding-world-web-shared';
import { NEXT_LOCALE_COOKIE } from '@constants/localization';
import { locales as SUPPORTED_LOCALES } from '../../../i18n';

const LocaleRouter = () => {
  const { getCookie, setCookie } = SharedLibs.utils.cookie;
  const router = useRouter();

  useEffect(() => {
    let nextLocaleCookie = getCookie(NEXT_LOCALE_COOKIE);

    if (!nextLocaleCookie) {
      let browserLanguage = navigator.languages ? navigator.languages[0] : navigator.language;

      // Strip off country and character set values from locale code
      browserLanguage =
        browserLanguage
          ?.split(',')?.[0]
          ?.split('-')?.[0]
          ?.toLowerCase() || 'en';

      if (!SUPPORTED_LOCALES.includes(browserLanguage)) {
        browserLanguage = 'en';
      }

      // Make sure a NEXT_LOCALE cookie is always set
      setCookie({
        name: NEXT_LOCALE_COOKIE,
        value: browserLanguage,
        expires: 365 * 24 * 60 * 60,
      });
      nextLocaleCookie = browserLanguage;
    }

    // Catch cookie set with an unsupported language
    if (nextLocaleCookie && !SUPPORTED_LOCALES.includes(nextLocaleCookie)) {
      // Make sure a NEXT_LOCALE cookie is always set
      const defaultLanguage = 'en';

      setCookie({
        name: NEXT_LOCALE_COOKIE,
        value: defaultLanguage,
        expires: 365 * 24 * 60 * 60,
      });
      nextLocaleCookie = defaultLanguage;
    }

    // Always redirect to the correct locale if NEXT_LOCALE cookie is set
    if (nextLocaleCookie && nextLocaleCookie !== router.locale) {
      router.replace(router.asPath, undefined, {
        locale: nextLocaleCookie,
        shallow: false,
      });
    }
  }, [router.asPath]);

  return null;
};

export default LocaleRouter;
